<template>
  <div>
   <manageAssistantTeacherBarVue/>
    <v-container fluid>
      <base-material-card
        icon="mdi-account-star"
        :title="
          'ข้อมูลครูผู้ช่วย รายงานตัว รอบที่ : ' +
            periodassteachs.periodAssTeachTimes +
            ' / ' +
            periodassteachs.periodAssTeachYear
        "
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
              <v-autocomplete
                v-model="SearchBranch"
                label="ค้นหาแยกตาม : สาขาวิชาเอก"
                outlined
                :items="manage_assistant_teacherGroupBranch"
                item-text="name_branch"
                item-value="mt_id_branch"
                @change="manage_assistant_teacherSearchBranchQueryAll()"
              >
                <template v-slot:item="{ item }">
                  <div class="pa-2">
                    <div class="font-weight-black">
                      {{ item.mt_id_branch }} : {{ item.name_branch }}
                    </div>
                    <div class="divTab50">
                      จำนวน :
                      {{ item.countMAT }} คน
                    </div>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" class="text-right">
              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_assistant_teacherTotal()"
              >
                <v-icon>mdi-clipboard-text</v-icon>สรุปจำนวนเรียกรายงานตัว
              </v-btn>
              <v-btn
                block
                right
                depressed
                color="info"
                @click.native="manage_assistant_teacherQueryAll()"
              >
                <v-icon>mdi-clipboard-text</v-icon>แสดงข้อมูลทั้งหมด
              </v-btn>
            </v-col>
            <v-col cols="12" md="3" class="text-right">
              <v-btn
                block
                right
                depressed
                color="warning"
                @click.native="manage_assistant_teacherApprove()"
              >
                <v-icon>mdi-checkbox-multiple-marked</v-icon>ยืนยันประกาศ
              </v-btn>
              <!--  -->
              <v-btn
              :href="'#/admin/print_manage_assistant_teacher_report/' +
                periodassteachs.periodAssTeachTimes +
                '/' +
                periodassteachs.periodAssTeachYear
                " target="_blank"
                block
                right
                depressed
                color="primary"
                @click.native="manage_assistant_teacherPrintReport()"
              >
                <v-icon>mdi-printer</v-icon>พิมพ์รายงานผล
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="manage_assistant_teachers"
          :search="search"
          group-by="mt_id_branch"
          class="elevation-1"
          :item-class="row_classes"
          disable-pagination
        >
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="20">
              <h2>
                <v-icon @click="toggle"
                  >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
                </v-icon>
                {{ items[0].mt_id_branch + " : " + items[0].name_branch }}
              </h2>
            </th>
          </template>

          <template v-slot:item.mt_sequence="{ item }">
            <v-chip dark color="info">
              <h2>{{ item.mt_sequence }}</h2>
            </v-chip>
          </template>

          <template v-slot:item.mt_id_card="{ item }">
            <span class="hide-idcard-number">{{ item.mt_id_card }}</span>
          </template>

          <template v-slot:item.mt_status="{ item }">
            <v-chip dark color="red" v-if="item.mt_status === 'disclaim'">
              <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
            </v-chip>
            <v-chip dark color="green" v-else>
              <v-icon>mdi-lightbulb-on-outline</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.actionSelect="{ item }">
            <div
              v-if="
                item.mt_admissions_status !== 'send' &&
                  item.mt_admissions_status !== 'receipt' && item.mt_admissions_status !== 'missing'
              "
            >
              <div v-if="item.mt_status !== 'disclaim'">
                <div v-if="item.mt_id === editedItem.mt_id">
                  <v-icon color="red" class="mr-3" @click="close">
                    mdi-window-close
                  </v-icon>
                  <v-icon color="green" @click="save()">
                    mdi-content-save
                  </v-icon>
                </div>
                <div v-else>
                  <v-icon color="green" class="mr-3" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    color="red"
                    @click="deleteItem(item.mt_id, item.mt_id_position)"
                  >
                    mdi-delete
                  </v-icon>
                </div>
              </div>
            </div>

            <div v-else>
              <v-chip dark color="warning">
                <v-icon dark color="black">mdi-cube-send</v-icon>
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_college_code="{ item }">
            <v-autocomplete
              v-model="editedItem.mt_college_code"
              :items="manage_college_manpowers"
              item-text="college_name"
              item-value="college_code"
              label="สถานศึกษา"
              @change="
                collegeIDpositionid(
                  item.mt_times,
                  item.mt_years,
                  editedItem.mt_college_code,
                  item.mt_id_branch
                )
              "
              v-if="item.mt_id === editedItem.mt_id"
            >
            </v-autocomplete>

            <span v-else>{{ item.college_name }}</span>
          </template>

          <template v-slot:item.mt_id_position="{ item }">
            <v-autocomplete
              v-model="editedItem.mt_id_position"
              label="เลขที่ตำแหน่ง"
              :items="manage_college_manpowerIDpostion"
              item-text="mcm_id_position"
              item-value="mcm_id_position"
              v-if="item.mt_id === editedItem.mt_id"
            >
              <template v-slot:item="{ item }">
                <div class="pa-2">
                  <div class="font-weight-black">
                    {{ item.mcm_id_position }}
                  </div>
                  <div class="divTab50">
                    สถานะ : {{ item.mcm_id_card_booking }}
                  </div>
                </div>
              </template>
            </v-autocomplete>

            <span v-else>{{ item.mt_id_position }}</span>
          </template>

          <template v-slot:item.actions="{ item }">
        
              <v-icon
              v-if="
                item.mt_admissions_status !== 'send' &&
                  item.mt_admissions_status !== 'receipt' && item.mt_admissions_status !== 'missing'
              "
                color="warning"
                @click.stop="manage_assistant_teacherEdit(item.mt_id)"
              >
                mdi-pencil
              </v-icon>            
              <v-icon v-else dark color="black">mdi-cube-send</v-icon>           
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon          
              v-if="
                item.mt_admissions_status !== 'send' &&
                  item.mt_admissions_status !== 'receipt' && item.mt_admissions_status !== 'missing'
              "
              color="red"
              @click.stop="manage_assistant_teacherDelete(item.mt_id)"
            >
              mdi-delete
            </v-icon>
            <v-chip v-else dark color="warning">
              <v-icon dark color="black">mdi-cube-send</v-icon>
            </v-chip>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model editmanage_assistant_teacherdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editmanage_assistant_teacherdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลครูผู้ช่วย รายงานตัว"
              class="px-5 py-3"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editmanage_assistant_teacherform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <v-text-field
                        outlined
                        label="เลขบัตรประชาชน"
                        v-model="editmanage_assistant_teacher.mt_id_card"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-autocomplete
                        :items="branchs"
                        item-text="name_branch"
                        item-value="id_branch"
                        outlined
                        label="สาขาวิชา"
                        v-model="editmanage_assistant_teacher.mt_id_branch"
                        required
                        :rules="[v => !!v || '']"
                      >
                        <template v-slot:item="{ item }">
                          <div class="pa-2">
                            <div class="divTab50">
                              {{ item.id_branch }} :
                              {{ item.name_branch }}
                            </div>
                          </div>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="คำนำหน้าชื่อ"
                        v-model="editmanage_assistant_teacher.mt_title_s"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ชื่อ"
                        v-model="editmanage_assistant_teacher.mt_frist_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="นามสกุล"
                        v-model="editmanage_assistant_teacher.mt_last_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="วันเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_day"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เดือนเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_month"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ปีเกิด"
                        v-model="editmanage_assistant_teacher.mt_brith_year"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เบอร์โทร"
                        v-model="editmanage_assistant_teacher.mt_tel_p"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ชื่อย่อวุฒิการศึกษา"
                        v-model="editmanage_assistant_teacher.mt_ed_abb"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="สาขาวิชาเอก"
                        v-model="editmanage_assistant_teacher.mt_ed_name"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="วันที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_day"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="เดือนที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_month"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="ปีที่บรรจุ"
                        v-model="editmanage_assistant_teacher.mt_appoin_year"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md4>
                      <v-text-field
                        outlined
                        label="คำสั่งที่"
                        v-model="editmanage_assistant_teacher.mt_order_app"
                        required
                        :rules="[v => !!v || '']"
                      ></v-text-field>
                    </v-flex>

                    <v-flex md4>
                      <v-autocomplete
                        :items="mt_status_select"
                        item-text="text"
                        item-value="value"
                        outlined
                        label="สถานะ"
                        v-model="editmanage_assistant_teacher.mt_status"
                        required
                        :rules="[v => !!v || '']"
                      ></v-autocomplete>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                outlined @click.stop="editmanage_assistant_teacherdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editmanage_assistant_teacherSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherCountDialog -->
      <v-layout>
        <v-dialog
          v-model="manage_assistant_teacherCountDialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              :title="
                'ข้อมูลสรุปครูผู้ช่วย รอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-data-table
                  color="success"
                  :loading="loading"
                  :headers="headersCount"
                  :items="manage_assistant_teacherCount"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      color="info"
                      @click.stop="
                        matBranchQuery(
                          item.mt_times,
                          item.mt_years,
                          item.mt_id_branch
                        )
                      "
                    >
                      mdi-account-search
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="manage_assistant_teacherCountDialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherApproveDialog -->
      <v-layout>
        <v-dialog
          persistent
          v-model="manage_assistant_teacherApproveDialog"
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-checkbox-multiple-marked"
              :title="
                'ยืนยันการรายงานตัวครูผู้ช่วย รอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-text-field
                  v-model="searchApprove"
                  append-icon="mdi-magnify"
                  label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                  single-line
                  hide-details
                  dense
                  filled
                  class="mb-2"
                />

                <v-data-table
                  color="success"
                  :loading="loading"
                  :headers="headersApprove"
                  :items="manage_assistant_teachers"
                  :search="searchApprove"
                >
                  <template v-slot:group.header="{ items, isOpen, toggle }">
                    <th colspan="20">
                      <h2>
                        <v-icon @click="toggle"
                          >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
                        </v-icon>
                        {{
                          items[0].mt_id_branch + " : " + items[0].name_branch
                        }}
                      </h2>
                    </th>
                  </template>

                  <template v-slot:item.mt_sequence="{ item }">
                    <v-chip dark color="info">
                      <h2>{{ item.mt_sequence }}</h2>
                    </v-chip>
                  </template>

                  <template v-slot:item.mt_id_card="{ item }">
                    <span class="hide-idcard-number">{{
                      item.mt_id_card
                    }}</span>
                  </template>

                  <template v-slot:item.mt_status="{ item }">
                    <v-chip
                      dark
                      color="red"
                      v-if="item.mt_status === 'disclaim'"
                    >
                      <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
                    </v-chip>
                    <v-chip dark color="green" v-else>
                      <v-icon>mdi-lightbulb-on-outline</v-icon>
                    </v-chip>
                  </template>

                  <template v-slot:item.actionSelect="{ item }">
                    <div v-if="item.mt_status !== 'disclaim'">
                      <div v-if="item.mt_id === editedItem.mt_id">
                        <v-icon color="red" class="mr-3" @click="close">
                          mdi-window-close
                        </v-icon>
                        <v-icon color="green" @click="save()">
                          mdi-content-save
                        </v-icon>
                      </div>
                      <div v-else>
                        <v-icon
                          color="green"
                          class="mr-3"
                          @click="editItem(item)"
                        >
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          color="red"
                          @click="deleteItem(item.mt_id, item.mt_id_position)"
                        >
                          mdi-delete
                        </v-icon>
                      </div>
                    </div>
                  </template>

                  <template v-slot:item.mt_college_code="{ item }">
                    <span>{{ item.college_name }}</span>
                  </template>

                  <template v-slot:item.mt_admissions_status="{ item }">
                    <v-chip
                      dark
                      color="warning"
                      v-if="item.mt_admissions_status === 'send'"
                    >
                      <v-icon dark color="black">mdi-cube-send</v-icon>
                    </v-chip>
                    <v-chip dark color="red" v-else>
                      <v-icon>mdi-close-circle</v-icon>
                    </v-chip>
                  </template>
                </v-data-table>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  color="primary"
                  @click="manage_assistant_teacherApproveSubmit()"
                >
                  <v-icon class="mr-5">mdi-checkbox-multiple-marked</v-icon>
                  ยืนยันข้อมูล
                </v-btn>
                <v-btn
                  color="warning"
                  @click="manage_assistant_teacherApproveCCSubmit()"
                >
                  <v-icon class="mr-5">mdi-closed-caption</v-icon>
                  ยกเลิกการยืนยัน
                </v-btn>
                <v-btn
                  color="blue-grey"
                  outlined
                  @click.stop="manage_assistant_teacherApproveDialog = false"
                >
                  <v-icon class="mr-5">mdi-close-circle-outline</v-icon>
                  ปิด
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherApproveCFDialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="manage_assistant_teacherApproveCFDialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-checkbox-multiple-marked"
              :title="
                'ยืนยันการรายงานตัวครูผู้ช่วย รอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="manage_assistant_teacherApproveCFDialogform"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="text-center red--text">
                        <h2>
                          กรุณาตรวจสอบข้อมูลให้เรียบร้อยก่อนการกดปุ่มยืนยันข้อมูล
                        </h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                large
                @click.stop="manage_assistant_teacherApproveCFDialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="manage_assistant_teacherApproveCFDialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model manage_assistant_teacherApproveCCDialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="manage_assistant_teacherApproveCCDialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-closed-caption"
              :title="
                'ยกเลิกการยืนยันการรายงานตัวครูผู้ช่วย รอบที่ : ' +
                  periodassteachs.periodAssTeachTimes +
                  ' / ' +
                  periodassteachs.periodAssTeachYear
              "
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form
                ref="manage_assistant_teacherApproveCCDialogform"
                lazy-validation
              >
                <v-container>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="12">
                      <div class="text-center red--text">
                        <h2>
                          ยกเลิกข้อมูลการยืนยันรายการ
                        </h2>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                large
                @click.stop="manage_assistant_teacherApproveCCDialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="manage_assistant_teacherApproveCCDialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import manageAssistantTeacherBarVue from '../../../components/admin/manageAssistantTeacherBar.vue';
export default {
  name: "HRvecManageAssistantTeacher",
  components:{manageAssistantTeacherBarVue},
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      file2: null,
      editmanage_assistant_teacherdialog: false,
      deletemanage_assistant_teacherdialog: false,
      manage_assistant_teacherCountDialog: false,
      manage_assistant_teacherApproveDialog: false,
      manage_assistant_teacherApproveCFDialog: false,
      manage_assistant_teacherApproveCCDialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_assistant_teachers: [],
      editmanage_assistant_teacher: {},
      colleges: [],
      userstatus: [],
      search: "",
      searchApprove: "",
      SearchBranch: "",
      pagination: {},
      headers: [
        { text: "รอบที่", align: "left", value: "mt_timeyear" },
        { text: "สาขาวิชา", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "ลำดับที่", align: "left", value: "mt_sequence" },

        { text: "รหัส", align: "left", value: "mt_id_card" },
        { text: "ชื่อ-นามสกุล", align: "left", value: "mt_name" },
        /*  { text: "เอกวิชา", align: "left", value: "mt_ed_name" }, */

        { text: "สถานะ", align: "left", value: "mt_status" },
        { text: "เลือก", align: "left", value: "actionSelect" },
        { text: "วิทยาลัย", align: "center", value: "mt_college_code" },
        { text: "เลขที่ตำแหน่ง", align: "center", value: "mt_id_position" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      headersApprove: [
        { text: "รอบที่", align: "left", value: "mt_timeyear" },
        { text: "สาขาวิชา", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "ลำดับที่", align: "left", value: "mt_sequence" },
        { text: "รหัส", align: "left", value: "mt_id_card" },
        { text: "ชื่อ-นามสกุล", align: "left", value: "mt_name" },
        { text: "สถานะ", align: "left", value: "mt_status" },
        { text: "วิทยาลัย", align: "left", value: "mt_college_code" },
        { text: "เลขที่ตำแหน่ง", align: "left", value: "mt_id_position" },
        { text: "สถานะ", align: "left", value: "mt_admissions_status" }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      college: {},
      provinces: [],
      prefectures: [],
      collgegs: [],
      manage_assistant_teacherstatus: [],
      regions: [],
      region_ena: true,
      data_syslog: {},
      branchs: [],
      periodassteachs: [],
      headersCount: [
        { text: "รอบที่", align: "left", value: "mt_times" },
        { text: "ปี", align: "left", value: "mt_years" },
        { text: "รหัส", align: "left", value: "mt_id_branch" },
        { text: "สาขาวิชา", align: "left", value: "branchName" },
        { text: "จำนวน", align: "left", value: "branchGroup" },
        { text: "แสดง", align: "left", value: "actions" }
      ],
      manage_assistant_teacherCount: [],
      manage_assistant_teacherGroupBranch: [],
      mt_status_select: [
        { text: "ปกติ", value: "normal" },
        { text: "สละสิทธิ์", value: "disclaim" }
      ],
      manage_college_manpowers: [],
      manage_college_manpowerIDpostion: [],

      editedIndex: -1,
      editedItem: {
        mt_id: 0,
        mt_college_code: "",
        statusAdd: ""
      },
      editedItemUpdate: {},
      defaultItem: {
        mt_id: 0,
        mt_college_code: "",
        statusAdd: "add"
      },

      deletedItemmat: {},
      deletedItemmcm: {},
      updatemanage_college_manpower: {},  
      manage_college_manpowersMt_id: [],
      upadtemanage_assistant_teacher: {}
    };
  },

  async mounted() {
    await this.periodassteachQuery();
    await this.branchQuery();
    await this.matCountQuery();
    await this.manage_assistant_teacherQueryAll();
    await this.manage_assistant_teacherGroupBranchQueryAll();
  },

  methods: {
    async manage_assistant_teacherApprove() {
      this.manage_assistant_teacherApproveDialog = true;
    },
    async manage_assistant_teacherApproveSubmit() {
      this.manage_assistant_teacherApproveCFDialog = true;
    },
    async manage_assistant_teacherApproveCFDialogSubmit() {
      this.upadtemanage_assistant_teacher.ApiKey = this.ApiKey;
      this.upadtemanage_assistant_teacher.mt_times = this.periodassteachs.periodAssTeachTimes;
      this.upadtemanage_assistant_teacher.mt_years = this.periodassteachs.periodAssTeachYear;
      this.upadtemanage_assistant_teacher.mt_admissions_status = "send";

      let resultmat = await this.$http.post(
        "manage_assistant_teacherApprove.update.php",
        this.upadtemanage_assistant_teacher
      );     
 
      this.updatemanage_college_manpower.ApiKey = this.ApiKey;
      this.updatemanage_college_manpower.mcm_times = this.periodassteachs.periodAssTeachTimes;
      this.updatemanage_college_manpower.mcm_years = this.periodassteachs.periodAssTeachYear;
      this.updatemanage_college_manpower.mcm_status = "send";

      let resultmcm = await this.$http.post(
        "manage_college_manpowerApprove.update.php",
        this.updatemanage_college_manpower
      );     
    


      if (resultmat.data.status == true && resultmcm.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "บันทึกข้อมูลผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      await this.manage_assistant_teacherQueryAll();
      this.manage_assistant_teacherApproveCFDialog = false;
    },

    async manage_assistant_teacherApproveCCSubmit() {
      this.manage_assistant_teacherApproveCCDialog = true;
    },

    async manage_assistant_teacherApproveCCDialogSubmit(){
      this.upadtemanage_assistant_teacher.ApiKey = this.ApiKey;
      this.upadtemanage_assistant_teacher.mt_times = this.periodassteachs.periodAssTeachTimes;
      this.upadtemanage_assistant_teacher.mt_years = this.periodassteachs.periodAssTeachYear;
      this.upadtemanage_assistant_teacher.mt_admissions_status = "";

      let resultmat = await this.$http.post(
        "manage_assistant_teacherApprove.update.php",
        this.upadtemanage_assistant_teacher
      );

      this.updatemanage_college_manpower.ApiKey = this.ApiKey;
      this.updatemanage_college_manpower.mcm_times = this.periodassteachs.periodAssTeachTimes;
      this.updatemanage_college_manpower.mcm_years = this.periodassteachs.periodAssTeachYear;
      this.updatemanage_college_manpower.mcm_status = "";

      let resultmcm = await this.$http.post(
        "manage_college_manpowerApprove.update.php",
        this.updatemanage_college_manpower
      );     

   


      if (resultmat.data.status == true && resultmcm.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "บันทึกข้อมูลผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      await this.manage_assistant_teacherQueryAll();
      this.manage_assistant_teacherApproveCCDialog = false;
    },

    async editItem(item) {
      this.editedIndex = this.manage_assistant_teachers.indexOf(item);
      this.editedItem = Object.assign({}, item);

      let result = await this.$http.post("manage_college_manpower.php", {
        ApiKey: this.ApiKey,
        mcm_times: item.mt_times,
        mcm_years: item.mt_years,
        mcm_id_branch: item.mt_id_branch
      });
      this.manage_college_manpowers = result.data;
    },

    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async collegeIDpositionid(
      mt_times,
      mt_years,
      mt_college_code,
      mt_id_branch
    ) {
      let result = await this.$http.post("manage_college_manpower.php", {
        ApiKey: this.ApiKey,
        mcm_times: mt_times,
        mcm_years: mt_years,
        mcm_college_code: mt_college_code,
        mcm_id_branch: mt_id_branch
      });
      this.manage_college_manpowerIDpostion = result.data;
    },

    async matBranchQuery(mt_times, mt_years, mt_id_branch) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_times: mt_times,
        mt_years: mt_years,
        mt_id_branch: mt_id_branch
      });
      this.manage_assistant_teachers = result.data;
      this.manage_assistant_teacherCountDialog = false;
    },

    async matCountQuery() {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_times: this.periodassteachs.periodAssTeachTimes,
        mt_years: this.periodassteachs.periodAssTeachYear,
        mt_count: "Ok"
      });
      this.manage_assistant_teacherCount = result.data;
    },

    async periodassteachQuery() {
      let result = await this.$http.post("periodassteach.php", {
        ApiKey: this.ApiKey,
        periodAssTeachEnablePro: "1"
      });
      this.periodassteachs = result.data;
    },

    async branchQuery() {
      let result = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.branchs = result.data;
    },

    async manage_assistant_teacherTotal() {
      await this.matCountQuery();
      this.manage_assistant_teacherCountDialog = true;
    },

    async sweetAlertLoading() {
      let timerInterval;
      Swal.fire({
        title: "Auto close alert!",
        html: "I will close in <b></b> milliseconds.",
        timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      }).then(result => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
          
        }
      });
    },

    async manage_assistant_teacherReplace() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          manage_assistant_teacherCheck: "Ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async manage_assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          report_selete:"ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async manage_assistant_teacherSearchBranchQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          mt_id_branch: this.SearchBranch
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async manage_assistant_teacherGroupBranchQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_times: this.periodassteachs.periodAssTeachTimes,
          mt_years: this.periodassteachs.periodAssTeachYear,
          mt_SELECTBranch: "Ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teacherGroupBranch = result.data;
    },

    async manage_assistant_teacherBranchIDQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          mt_id_branch: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
    },

    async save() {
      this.editedItem.ApiKey = this.ApiKey;
      Object.assign(
        this.manage_assistant_teachers[this.editedIndex],
        this.editedItem
      );

      let resultmat = await this.$http.post(
        "manage_assistant_teacherID.update.php",
        this.editedItem
      );


      this.updatemanage_college_manpower.ApiKey = this.ApiKey;
      this.updatemanage_college_manpower.mcm_times = this.editedItem.mt_times;
      this.updatemanage_college_manpower.mcm_years = this.editedItem.mt_years;
      this.updatemanage_college_manpower.mcm_id_position = this.editedItem.mt_id_position;
      this.updatemanage_college_manpower.mcm_id_card_booking = this.editedItem.mt_id_card;

      let resultmcm = await this.$http.post(
        "manage_college_manpowerID.update.php",
        this.updatemanage_college_manpower
      );

     

      if (resultmat.data.status == true && resultmcm.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ทำรายการซ้ำ",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.manage_assistant_teacherQueryAll();
      this.close();
    },

    async manage_assistant_teacherEdit(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.editmanage_assistant_teacherdialog = true;
    },
    async editmanage_assistant_teacherSubmit() {
      if (this.$refs.editmanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        if (this.editmanage_assistant_teacher.mt_status == "normal") {
          this.editmanage_assistant_teacher.mt_status == "";
        }

        let result = await this.$http.post(
          "manage_assistant_teacher.update.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการแก้ไขข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.editmanage_assistant_teacherdialog = false;
      }
    },
    async manage_assistant_teacherDelete(mt_id) {
      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.editmanage_assistant_teacher = result.data;
      this.deletemanage_assistant_teacherdialog = true;
    },
    async deletemanage_assistant_teacherSubmit() {
      if (this.$refs.deletemanage_assistant_teacherform.validate()) {
        this.editmanage_assistant_teacher.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "manage_assistant_teacher.delete.php",
          this.editmanage_assistant_teacher
        );
        if (result.data.status == true) {
          this.manage_assistant_teacher = result.data;
          Swal.fire({
            icon: "success",
            title: "ดำเนินการข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "manage_assistant_teacher";
          this.data_syslog.table_log = "manage_assistant_teacher";
          this.data_syslog.detail_log =
            this.editmanage_assistant_teacher.id_position +
            " : " +
            this.editmanage_assistant_teacher.college_code +
            " : " +
            this.editmanage_assistant_teacher.case_vacancy;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
          this.manage_assistant_teacherQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.deletemanage_assistant_teacherdialog = false;
      }
    },

    async deleteItem(mt_id, mt_id_position) {
      this.deletedItemmat.ApiKey = this.ApiKey;
      this.deletedItemmat.mt_id = mt_id;
      this.deletedItemmat.mt_college_code = "";
      this.deletedItemmat.mt_id_position = "";

      let result = await this.$http.post("manage_assistant_teacher.php", {
        ApiKey: this.ApiKey,
        mt_id: mt_id
      });
      this.manage_college_manpowersMt_id = result.data;
      ;

      this.deletedItemmcm.ApiKey = this.ApiKey;
      this.deletedItemmcm.mcm_times = this.manage_college_manpowersMt_id.mt_times;
      this.deletedItemmcm.mcm_years = this.manage_college_manpowersMt_id.mt_years;
      this.deletedItemmcm.mcm_id_position = mt_id_position;
      this.deletedItemmcm.mcm_id_card_booking = "";

      let college_name =
        this.manage_college_manpowersMt_id.mt_title_s +
        this.manage_college_manpowersMt_id.mt_frist_name +
        this.manage_college_manpowersMt_id.mt_last_name +
        " : " +
        this.manage_college_manpowersMt_id.college_name +
        " : " +
        this.manage_college_manpowersMt_id.mt_id_position;
      Swal.fire({
        title: "คุณต้องการลบรายการนี้?",
        text: college_name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรายการ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          let resultupdatemat = await this.$http.post(
            "manage_assistant_teacher.update.php",
            this.deletedItemmat
          );
    

          let resultupdatemcm = await this.$http.post(
            "manage_college_manpower.update.php",
            this.deletedItemmcm
          );
     
        }
        this.manage_assistant_teacherQueryAll();
      });
    },

    row_classes(item) {
      if (item.mt_status == "disclaim") {
        return "lime";
      }
    }
  },

  computed: {
    color() {
      return "teal";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>

<style scoped>
.hide-idcard-number {
  display: inline-block;
  font-family: monospace, monospace;
  position: relative;
}

.hide-idcard-number::after {
  content: "XXXXXXXX";
  background: white;
  position: absolute;
  left: 0;
}
</style>
